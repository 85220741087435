import {
    DocumentsMessagesPopupComponent
} from "../second-step/documents-messages-popup/documents-messages-popup.component";
import {CreditModel} from "../../../../../../core/ek-e-commerce/ek-models/credit.model";
import {MonthlyPaymentPipe} from "../../../../../../core/_base/layout";
import {CartMessages} from "./folder-step-one/folder-step-one.component";
import {FormGroup} from "@angular/forms";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {Actions, ofType} from "@ngrx/effects";
import {Update} from "@ngrx/entity";
import {BehaviorSubject, Subscription} from "rxjs";
import {take} from "rxjs/operators";
import {DocumentModel} from "../../../../../../core/ek-e-commerce/ek-models/document.model";
import {EkSnackBarService} from "../../../../../../core/services/ek-snackBar.service";
import {DocumentService} from "../../../../../../core/services/document.service";
import {ChangeDetectorRef, Component, Inject, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute, ParamMap, Router} from "@angular/router";
import {OrderSplitService} from "../../../../../../core/ek-e-commerce/ek-services/order-split.service";
import {OrderSplitAdmin} from "../../../../../../core/ek-e-commerce/ek-models/OrderSplitAdmin";
import {FolderModel} from "../../../../../../core/ek-e-commerce/ek-models/folder.model";
import {FoldersService} from "../../../../../../core/ek-e-commerce/ek-services/folders.service";
import {select, Store} from "@ngrx/store";
import {AppState} from "../../../../../../core/reducers";
import {OrderSplit} from "../../../../../../core/ek-e-commerce/ek-models/orderSplit";
import {ClientsService} from "../../../../../../core/ek-e-commerce/ek-services/clients.service";
import {LayoutUtilsService, MessageType,} from "../../../../../../core/_base/crud";
import {PointOfSaleModel} from "../../../../../../core/ek-e-commerce/ek-models/point-of-sale.model";
import {ClientModel} from "../../../../../../core/ek-e-commerce/ek-models/client.model";
import {
    OrderSplitCreated,
    OrderSplitCreatedSuccessfully,
    OrderSplitCreationFailed,
    OrderSplitUpdated,
    OrderSplitUpdatedSuccessfully,
    OrderSplitUpdateFailed,
} from "../../../../../../core/ek-e-commerce/ek-actions/orderSplit.actions";
import {ocrInfo} from "../../../../../../core/ek-e-commerce/ek-models/document-ocr-information";
import {OneByOneValidations} from "../../../../../../core/ek-e-commerce/ek-models/ocr-validations";
import {OrderSplitUpdateAdmin} from "../../../../../../core/ek-e-commerce/ek-models/orderSplitUpdateAdmin";
import {selectLastCreatedOrderSplitId} from "../../../../../../core/ek-e-commerce/ek-selectors/orderSplit.selectors";
import {
    ClientOcrInformationService
} from "../../../../../../core/ek-e-commerce/ek-services/clientOcrInformation.service";
import {
    FilesSecondErrorPopupComponent
} from "./folder-step-two/files-second-error-popup/files-second-error-popup.component";
import {
    SecondStepConfirmationPopupComponent
} from "./folder-step-two/second-step-confirmation-popup/second-step-confirmation-popup.component";
import {CustomersService} from "../../../../../../core/e-commerce";
import {FolderVerificationService} from "../../../../../../core/services/folder-verification.service";
import {EkClientEmailComponent} from "../../ek-customers/ek-client-email/ek-client-email.component";
import {DatePipe, DOCUMENT} from "@angular/common";
import * as ClientActions from "../../../../../../core/ek-e-commerce/ek-actions/client.actions";
import {BANK_LENDER} from "../../../Shared/Constants/bank-lender";
import {DownloadFileDialoguComponent} from "./folder-step-two/download-file-dialogu/download-file-dialogu.component";
import {BANKER_STATES} from "../../../../../../../assets/constancies/banker.states";
import {
    PhoneNumberValidationPopupComponent
} from "../phone-number-validation-popup/phone-number-validation-popup.component";


enum errorCode {
    HTF = "Vous avez dépasser la limite max d'achat autorisé",

    LTF = "Montant d'achat inferieur à limite minimum autorisé",

    HTC = "Montant de commande supérieur à la capacité d'achat",

    LTC = "LowerThanCapacity",

    HTM = "Mensualité est supérieur à 30% du salaire",
}


@Component({
    selector: "kt-folder-steps",
    templateUrl: "./folder-steps.component.html",
    styleUrls: ["./folder-steps.component.scss"],
    providers: [MonthlyPaymentPipe],
})
export class FolderStepsComponent implements OnInit, OnDestroy {
    //Validation values
    oneByOneValidations: OneByOneValidations = {
        ficheFamilliale: true,
        extraitDeNaissance: true,
        residence: true,
        chifa: true,
        FichePaie1: true,
        FichePaie2: true,
        FichePaie3: true,
        ccp: true,
        attestationDeTravail: true,
        retraite: true,
        faces: true,
        rena: true,
        radiation: true,
        cni: true,
        cni_verso: true,
        releveDeCompte: true,
        cheque: true,
        incomeMilitary: true,
    };

    min = 100000;
    max = 2000000;

    Subs: Subscription[] = [];

    ocrInfos!: ocrInfo;

    ocrInfosForm!: FormGroup;

    public OrdersTitle: String;

    step = 1;

    isNewOrder = false;
    isEditable!: boolean;
    isValid = false;

    orderCreationEntity: OrderSplitAdmin;
    client: ClientModel;
    clientId: number = 0;
    idFolder: number = 0;
    idOrder: number = 0;
    folder: FolderModel;
    orderSplit: OrderSplit;
    simulationForm: FormGroup;
    selectedRelay: PointOfSaleModel;
    showCartLimit = true;
    cartMessage: CartMessages = {
        isLimited: false,
        messages: "",
    };

    loadingRecapSubject = new BehaviorSubject<boolean>(false);
    readonly loadingRecap$ = this.loadingRecapSubject.asObservable();

    loadingSubject$ = new BehaviorSubject<boolean>(false);
    readonly loading$ = this.loadingSubject$.asObservable();

    private _myData$ = new BehaviorSubject<ClientModel>(null);
    public clientSplitObs$ = this._myData$.asObservable();

    loadingFilesSubject = new BehaviorSubject<boolean>(false);
    readonly isExporting = this.loadingFilesSubject.asObservable();


    personnalSituation: string;
    profSituation: string;
    loading: boolean;
    civileDocuments: any;
    profDocuments: any;
    garantyDocuments: any;

    cartCapacity = null;
    bankDocuments: any;
    isChecked: boolean;
    noDucmentMissing: boolean;
    total: number;
    needSomeChanges: boolean = null as any;
    currentRole: string = "";
    credit: CreditModel;
    canDownload: boolean;
    threeToTow: boolean = false;
    updateToTwo: boolean = false;
    requestNumber: string;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private activatedRoute: ActivatedRoute,
        private route: ActivatedRoute,
        private orderSplitService: OrderSplitService,
        public  foldersService: FoldersService,
        private documentService: DocumentService,
        private router: Router,
        private store: Store<AppState>,
        private clientsService: ClientsService,
        private layoutUtilsService: LayoutUtilsService,
        private _actions$: Actions,
        private EkSnackBar: EkSnackBarService,
        private dialog: MatDialog,
        private clientOcrInformationService: ClientOcrInformationService,
        private datePipe: DatePipe,
        private cdRef: ChangeDetectorRef,
        private customersService: CustomersService,
        public  folderVerificationService: FolderVerificationService,
        private mensualPipe: MonthlyPaymentPipe
    ) {
        this.currentRole = JSON.parse(localStorage.getItem("currentUser")).roles;
    }

    ngOnInit(): void {
        this.threeToTow = false;
        this.updateToTwo = false;

        this.activatedRoute.paramMap.subscribe((res: ParamMap) => {
            this.OrdersTitle = this.activatedRoute.snapshot.data["title"];
        });
        this.route.queryParams.subscribe((params: any) => {
            this.step = params["step"] ? Number(params["step"]) : 1;
        });


        // Empty Order - adding new order
        this.route.paramMap.subscribe((params) => {
            this.clientId = Number(params.get("idClient"));
            this.idOrder = Number(params.get("idOrder"));
            this.idFolder = Number(params.get("idFolder"));

            //new order
            if (this.clientId > 0) {
                this.isNewOrder = true;
                this.loadClient(this.clientId);
            }
            //update order
            else {
                this.loadOrderSplitByIdService(this.idOrder);
            }
        });

        this.getCreditDeets();

        this.documentService
            .getDocumentsByFolderId(this.idFolder)
            .subscribe((res: any) => {
                if (res) {
                    this.folderVerificationService.documents$.next(res.body);
                }
            });

        this.documentService
            .getValidationResponse(this.idFolder)
            .subscribe((res) => {
                if (res) {
                    this.oneByOneValidations = this.folderVerificationService.verifyDocuments(res.body);
                    this.folderVerificationService.getMessages(res.body);
                    this.needSomeChanges = this.folderVerificationService.needSomeChanges;
                    let temp = this.areAllChecked(this.oneByOneValidations);
                    this.documentService.allOneByOneIsCheckedSubject.next(temp);
                }
            });

        this.Subs.push(
            this.documentService
                .getDocumentsByFolderId(this.idFolder)
                .subscribe((res: any) => {
                    this.civileDocuments = res.body.filter(
                        (document: DocumentModel) =>
                            document.documentsType === "CIVILIAN_STATE"
                    );
                    this.profDocuments = res.body.filter(
                        (document: DocumentModel) =>
                            document.documentsType === "PROFESSIONAL"
                    );
                    this.garantyDocuments = res.body.filter(
                        (document: DocumentModel) => document.documentsType === "GUARANTEES"
                    );
                    this.bankDocuments = res.body.filter(
                        (document: DocumentModel) => document.documentsType === "BANK"
                    );
                })
        );

        this.orderSplitService.createdNewOrder$.subscribe((res) => {
            if (res) {
                this.orderCreationEntity = res;
            }
        });

        this.Subs.push(
            this.orderSplitService.selectedRelaySubject$.subscribe((change) => {
                this.selectedRelay = change;
            })
        );

        this.Subs.push(
            this.foldersService.selectedFolderSubject$.subscribe((res) => {
                if (res) {
                    this.folder = res;
                    this.requestNumber = res.requestNumber;
                    this.foldersService.isChecked(this.idFolder).subscribe((res) => {
                        if (res) {
                            if (res.body == false) {
                                //get client info from ocr
                                this.isChecked = true;
                                this.foldersService.isCheckSbj$.next(true);
                                this.getClientInformations();
                            } else {
                                this.documentService.ocrSubject.next(null as any);
                                this.isChecked = false;
                                this.foldersService.isCheckSbj$.next(false);
                            }
                        }
                    });
                }
            })
        );

        this.foldersService.folderStateSubj$.subscribe((state) => {
            if (
                state === "EK_GUARTENTEES_PENDING_SIGNATURE" ||
                state === "EK_GUARTENTEES_SIGNED_IN_PROGRESS" ||
                state === "EK_PHYSICAL_FOLDER_PENDING" ||
                state === "EK_PHYSICAL_FOLDER_WAITING_FOR_PICK" ||
                state === "EK_WAITING_FOR_CLIENT" ||
                state === "EK_PHYSICAL_FOLDER_PICKEDUP" ||
                state === "GUARTENTEES_SIGNED_IN_PROGRESS_BANK" ||
                state === "EK_PHYSICAL_FOLDER_IN_DELIVERY" ||
                state === "EK_PHYSICAL_FOLDER_DELIVERED" ||
                state === "EK_PHYSICAL_FOLDER_IN_PROGRESS" ||
                state === "EK_BANK_PHYSICAL_FOLDER_IN_DELIVERY" ||
                state === "EK_BANK_PHYSICAL_FOLDER_IN_PROGRESS" ||
                state === "EK_BANK_PHYSICAL_FOLDER_ACCEPTED" ||
                state === "EK_BANK_PHYSICAL_FOLDER_RESERVE" ||
                state === "EK_BANK_REFUSED" ||
                state === "BANK_NOTIFICATION" ||
                state === "EK_FOLDER_REFUSED" ||
                state === "EK_ADMIN_FOLDER_CANCELED" ||
                state === "EK_CLIENT_NO_RESPONSE" ||
                state === "EK_FOLDER_PICKUP_FAILED"
            ) {
                this.canDownload = true;
            } else {
                this.canDownload = false;
            }
        });


        //if child component change order split
        this.orderSplitService.selectedOrder$.subscribe(res => {
            this.orderSplit = res;
        });


    }

    checkCart() {

        this.cartMessage = {
            isLimited: false,
            messages: "",
        };

        this.total = this.orderSplitService.total;


        if (this.total < this.min && this.total < this.cartCapacity) {
            this.cartMessage.isLimited = true;
            this.cartMessage.messages = errorCode.LTF;
            return;
        }

        if (this.total > this.max && this.total < this.cartCapacity) {
            this.cartMessage.isLimited = true;
            this.cartMessage.messages = errorCode.HTF;
            return;
        }

        if (this.total > this.max || this.total > this.cartCapacity) {
            this.cartMessage.isLimited = true;
            this.cartMessage.messages = errorCode.HTC;
            return;
        }

        const selectedMonths = +this.simulationForm.controls.monthlyPayment.value;
        if (
            this.mensualPipe.transform(this.total, selectedMonths) >
            +this.orderCreationEntity.salarySimulation * 0.3
        ) {
            this.cartMessage.isLimited = true;
            this.cartMessage.messages = errorCode.HTM;
            return;
        }
        return this.cartMessage;
    }

    getClientInformations() {
        //get client info from ocr
        this.Subs.push(
            this.documentService.getClientInfo(this.idFolder).subscribe({
                next: (res) => {
                    this.ocrInfos = res.body;
                    this.documentService.ocrSubject.next(res.body);
                    // this.dialog.closeAll();
                },
                error: (error) => {
                    this.loading = false;
                    let msg = "Veuillez reéssayer à nouveau";
                    this.EkSnackBar.openSnackBar(msg, "ok", "error-snackbar");
                },
            })
        );
    }

    manipulateStepOne() {
        this.checkCart();
        if (this.currentRole === "ROLE_BANKER_EK") {
            this.step = 2;
            this.router.navigate([], {queryParams: {step: 2}});
        } else {
            if (this.isNewOrder) {
                this.createOrderSplit();
            } else {
                this.updateOrderSplitAdmin();
            }
        }
    }

    getSimulationInfoFG(event: FormGroup) {
        this.simulationForm = event;
    }

    nextStep(nextStep, previous?: boolean) {
        switch (nextStep) {
            case 1:
                this.step = nextStep;
                this.router.navigate([], {queryParams: {step: nextStep}});
                break;
            case 2:
                if (this.threeToTow) {
                    this.threeToTow = false;
                    this.updateToTwo = true;
                    this.router.navigate([], {queryParams: {step: 2}});
                    this.updateClientInformationStep3();
                } else {
                    this.step = nextStep;
                    this.router.navigate([], {queryParams: {step: nextStep}});
                }
                break;
            case 3: {
                this.foldersService.isCheckSbj$.subscribe((res) => {
                    this.isChecked = res;
                });
                if (this.step > nextStep) {
                    this.step = nextStep;
                    this.router.navigate([], {queryParams: {step: nextStep}});
                } else {
                    this.foldersService.isCheckSbj$.subscribe((res) => {
                        this.isChecked = res;
                    });
                    this.documentService.ocrSubject.pipe(take(1)).subscribe((res) => {
                        if (res && this.isChecked) {
                            this.step = nextStep;
                            this.router.navigate([], {queryParams: {step: nextStep}});
                            return;
                        } else {
                            if (!this.isChecked) {
                                this.loading = true;
                                this.dialog.open(SecondStepConfirmationPopupComponent, {
                                    width: "480px",
                                });
                                this.Subs.push(
                                    this.documentService
                                        .verifyDocuments(this.idFolder)
                                        .subscribe({
                                            next: (res) => {
                                                this.loading = false;
                                                if (res.body) {
                                                    let keyFr = "";
                                                    this.dialog.closeAll();
                                                    Object.keys(res.body).forEach((key) => {
                                                        this.noDucmentMissing = true;
                                                        switch (key) {
                                                            case "FAMILY_SHEET":
                                                                keyFr = "Fiche Familliale";
                                                                this.noDucmentMissing = false;
                                                                break;
                                                            case "FRONT_IDENTITY_CARD":
                                                                keyFr = "Carte d'identité (recto)";
                                                                this.noDucmentMissing = false;
                                                                break;
                                                            case "BACK_IDENTITY_CARD":
                                                                keyFr = "Carte d'identité (verso)";
                                                                this.noDucmentMissing = false;
                                                                break;
                                                            case "PHOTO_IDENTITY":
                                                                keyFr = "Photo d'identité";
                                                                this.noDucmentMissing = false;
                                                                break;
                                                            case "RESIDENCE":
                                                                keyFr = "Residence";
                                                                this.noDucmentMissing = false;
                                                                break;
                                                            case "BIRTH_ACT":
                                                                keyFr = "Extrait de naissance";
                                                                this.noDucmentMissing = false;
                                                                break;
                                                            case "CARD_CHIFA":
                                                                keyFr = "Carte chiffa";
                                                                this.noDucmentMissing = false;
                                                                break;
                                                            case "CROSSED_CHECK":
                                                                keyFr = "Chèque barré";
                                                                this.noDucmentMissing = false;
                                                                break;
                                                            case "PAY_SLIP":
                                                                keyFr = "Fiche de paie 1";
                                                                this.noDucmentMissing = false;
                                                                break;
                                                            case "PAYSLIP_TWO":
                                                                keyFr = "Fiche de paie 2";
                                                                this.noDucmentMissing = false;
                                                                break;
                                                            case "PAYSLIP_THREE":
                                                                keyFr = "Fiche de paie 3";
                                                                this.noDucmentMissing = false;
                                                                break;
                                                            case "FEES_STATEMENT":
                                                                keyFr = "Relevé des emoluments";
                                                                this.noDucmentMissing = false;
                                                                break;
                                                            case "WORK_CERTIFICATE":
                                                                keyFr = "Attestation de travail";
                                                                this.noDucmentMissing = false;
                                                                break;
                                                            case "BANK_STATEMENT":
                                                                keyFr = "Relevé bancaire";
                                                                this.noDucmentMissing = false;
                                                                break;
                                                            case "DIRECT_DEBIT_CERTIFICATE":
                                                                keyFr = "Autorisation de prélèvement";
                                                                this.noDucmentMissing = false;
                                                                break;
                                                            case "PERMISSION_TO_CONSULT_THE_RISK_CENTER":
                                                                keyFr =
                                                                    "Autorisation de consultation de la centrale des riques";
                                                                this.noDucmentMissing = false;
                                                                break;
                                                            case "REQUEST_FOR_FINANCING":
                                                                keyFr = "Demande de financement";
                                                                this.noDucmentMissing = false;
                                                                break;
                                                            case "ACCOUNT_OPENING_REQUEST":
                                                                keyFr = "Demande ouverture de compte";
                                                                this.noDucmentMissing = false;
                                                                break;
                                                            case "COMMITMENT_TO_MAINTAIN_SUFFICIENT_BALANCE_UNDER_CCP_ACCOUNT":
                                                                this.noDucmentMissing = false;
                                                                keyFr =
                                                                    "Engagement de maintien solde suffisant sous compte CCP";
                                                                this.noDucmentMissing = false;
                                                                break;
                                                            case "TERMINATION_CERTIFICATE":
                                                                keyFr = "Attestation Radiation";
                                                                this.noDucmentMissing = false;
                                                                break;
                                                            case "CORP_PRESENCE":
                                                                keyFr = "Présence au Corp";
                                                                this.noDucmentMissing = false;
                                                                break;
                                                            case "INCOME_CERTIFICATE":
                                                                keyFr = "Attestation de Revenue";
                                                                this.noDucmentMissing = false;
                                                                break;
                                                            case "INCOME_CERTIFICATE_MILITARY":
                                                                keyFr = "Attestation de Revenue";
                                                                this.noDucmentMissing = false;
                                                                break;
                                                            case "RENA":
                                                                keyFr = "RENA";
                                                                this.noDucmentMissing = false;
                                                                break;
                                                        }
                                                    });

                                                    //Popup erreur 1
                                                    if (!this.noDucmentMissing) {
                                                        let msg = `Le document ${keyFr} est manquant `;
                                                        this.EkSnackBar.openSnackBar(
                                                            msg,
                                                            "",
                                                            "error-snackbar"
                                                        );
                                                        this.openErrorPopup();
                                                    }

                                                    if (this.noDucmentMissing) {
                                                        //Puting the documents in the subject
                                                        this.documentService
                                                            .getDocumentsByFolderId(this.idFolder)
                                                            .subscribe((res: any) => {
                                                                if (res) {
                                                                    this.folderVerificationService.documents$.next(
                                                                        res.body
                                                                    );
                                                                }
                                                            });

                                                        //verify documents
                                                        this.oneByOneValidations =
                                                            this.folderVerificationService.verifyDocuments(
                                                                res.body
                                                            );
                                                        this.folderVerificationService.getMessages(
                                                            res.body
                                                        );
                                                        this.needSomeChanges =
                                                            this.folderVerificationService.needSomeChanges;

                                                        this.foldersService.isCheckSbj$.next(true);

                                                        this.Subs.push(
                                                            this.documentService
                                                                .getClientInfo(this.idFolder)
                                                                .subscribe({
                                                                    next: (res) => {
                                                                        this.loading = false;
                                                                        this.ocrInfos = res.body;
                                                                        this.ocrInfos.phone =
                                                                            this.orderSplit?.phone;

                                                                        this.documentService.ocrSubject.next(
                                                                            res.body
                                                                        );
                                                                        this.step = nextStep;
                                                                        this.router.navigate([], {
                                                                            queryParams: {step: nextStep},
                                                                        });
                                                                        this.cdRef.detectChanges();
                                                                        this.dialog.closeAll();
                                                                        if (
                                                                            !this.areAllChecked(
                                                                                this.oneByOneValidations
                                                                            )
                                                                        ) {
                                                                            this.needSomeChanges =
                                                                                this.folderVerificationService.needSomeChanges;
                                                                            // this.openErrorPopup()
                                                                            if (this.needSomeChanges) {
                                                                                this.openMessagesPopup();
                                                                            }
                                                                        }
                                                                    },
                                                                    error: (error) => {
                                                                        this.loading = false;
                                                                        let msg = "Veuillez reéssayer à nouveau";
                                                                        this.EkSnackBar.openSnackBar(
                                                                            msg,
                                                                            "ok",
                                                                            "error-snackbar"
                                                                        );
                                                                        this.dialog.closeAll();
                                                                    },
                                                                })
                                                        );
                                                    }
                                                }
                                            },
                                            error: () => {
                                                this.dialog.closeAll();
                                                let msg =
                                                    "Connexion avec le serveur a échoué, veuillez réessayer à nouveau";
                                                this.EkSnackBar.openSnackBar(
                                                    msg,
                                                    "ok",
                                                    "error-snackbar"
                                                );
                                            },
                                        })
                                );
                            } else {
                                this.dialog.open(SecondStepConfirmationPopupComponent, {
                                    width: "480px",
                                });
                                this.documentService.getClientInfo(this.idFolder).subscribe({
                                    next: (res) => {
                                        this.loading = false;
                                        this.ocrInfos = res.body;
                                        this.ocrInfos.phone = this.orderSplit?.phone;

                                        this.documentService.ocrSubject.next(res.body);
                                        this.dialog.closeAll();
                                        this.step = nextStep;
                                        this.router.navigate([], {
                                            queryParams: {step: nextStep},
                                        });
                                        this.cdRef.detectChanges();
                                    },
                                    error: (error) => {
                                        this.loading = false;
                                        let msg = "Veuillez reéssayer à nouveau";
                                        this.EkSnackBar.openSnackBar(msg, "ok", "error-snackbar");
                                        this.dialog.closeAll();
                                    },
                                });
                            }
                        }
                    });
                }
                break;
            }
            case 4:
                //if POS has not the permission to update client info ...
                if (
                    this.currentRole === "ROLE_POS_EK" &&
                    this.folder.folderState !== "EK_FOLDER_CREATED" &&
                    this.folder.folderState !== "EK_FOLDER_RESERVE"
                ) {
                    this.step = 4;
                    this.router.navigate([], {queryParams: {step: this.step}});
                    return;
                }

                //if it is banker go directly to step 4
                if (
                    this.currentRole === "ROLE_BANKER_EK" ||
                    (this.currentRole === "ROLE_CREDIT_ANALYST_EK" && this.folder.folderState !== 'EK_FOLDER_IN_PROGRESS' && this.folder.folderState !== 'EK_BANK_RESERVE')
                ) {
                    this.step = 4;
                    this.router.navigate([], {queryParams: {step: this.step}});
                } else {

                    if (this.ocrInfosForm && this.ocrInfosForm.invalid) {

                        Object.keys(this.ocrInfosForm.controls).forEach((controlName) =>
                            this.ocrInfosForm.controls[controlName].markAsTouched()
                        );

                        const message = `vous devez remplir tous les champs obligatoires !`;
                        this.layoutUtilsService.showActionNotification(
                            message,
                            MessageType.Update,
                            5000,
                            true,
                            true
                        );

                        return;
                    }

                    this.updateClientInformationStep3();
                }
                break;
            case 5: {
                console.log('check bank doooocs');
                if (this.step > nextStep) {
                    if (
                        (this.currentRole === "ROLE_POS_EK" &&
                            this.folder.folderState !== "EK_FOLDER_CREATED" &&
                            this.folder.folderState !== "EK_FOLDER_RESERVE") ||
                        (this.currentRole === "ROLE_CREDIT_ANALYST_EK" &&
                            this.folder.folderState !== "EK_BANK_RESERVE" &&
                            this.folder.folderState !== "EK_FOLDER_IN_PROGRESS")
                    ) {
                        //click on previous button
                        if (previous) {
                            this.step = nextStep;
                            this.router.navigate([], {queryParams: {step: nextStep}});
                            return;
                        }

                        return;

                    } else {
                        this.step = nextStep;
                        this.router.navigate([], {queryParams: {step: nextStep}});
                    }
                } else {
                    console.log('check bank doooocs 222');

                    this.documentService.checkBankDocuments(this.idFolder).subscribe({
                        next: (res: any) => {
                            if (
                                String(res.body).includes("The BANK documents are not uploaded")
                            ) {
                                this.documentService.isBankDocCheckedSubject.next(false);
                                let msg = "Veuillez ajouter tous les documents";
                                this.EkSnackBar.openSnackBar(msg, "ok", "error-snackbar");
                            } else {
                                this.documentService.isBankDocCheckedSubject.next(true);

                                if (
                                    (this.currentRole === "ROLE_POS_EK" &&
                                        this.folder.folderState !== "EK_FOLDER_CREATED" &&
                                        this.folder.folderState !== "EK_FOLDER_RESERVE") ||
                                    (this.currentRole === "ROLE_CREDIT_ANALYST_EK" &&
                                        this.folder.folderState !== "EK_BANK_RESERVE" &&
                                        this.folder.folderState !== "EK_FOLDER_IN_PROGRESS")
                                ) {
                                    return;
                                } else {
                                    this.step = nextStep;
                                    this.router.navigate([], {queryParams: {step: nextStep}});
                                }
                            }
                        },
                        error: (error: any) => {
                            this.documentService.isBankDocCheckedSubject.next(false);
                            let msg = "Connexion avec le serveur a échoué";
                            this.EkSnackBar.openSnackBar(msg, "ok", "error-snackbar");
                        },
                    });
                }
                break;
            }
            case 6:
                this.router.navigate([], {queryParams: {step: nextStep}});
                break;
            case 7:
                this.router.navigate([], {queryParams: {step: nextStep}});
                break;
        }
    }

    // If product didn't find in store
    loadFolderByIdService(folderID) {
        this.foldersService.getFolderById(folderID).subscribe((res) => {
            if (res) {
                this.foldersService.folderSbj$.next(res);
                this.foldersService.selectedFolderSubject$.next(res);
                this.folder = res;
            }
        });
    }

    // If product didn't find in store
    loadOrderSplitByIdService(orderID) {
        this.orderSplitService.getOrderSplitById(orderID).subscribe((res) => {
            if (res) {
                this.orderSplit = res;
                this.orderSplitService.selectedOrderSubject.next(res);
                this.loadClient(res.clientId);
                this.loadFolderByIdService(this.idFolder);
            }
        });
    }

    loadClient(idClient) {
        this.clientsService.getFolderClientById(idClient).subscribe((res) => {
            if (res) {
                this._myData$.next(res);
                this.client = res;
                //see useless code
                this.client?.socialClientCategory
                    ? (this.personnalSituation = this.client?.socialClientCategory)
                    : "";
                this.client?.professionalClientCategory
                    ? (this.profSituation = this.client?.professionalClientCategory)
                    : "";
            }
        });
    }

    createOrderSplit() {
        //ROLE_BANKER_EK and ROLE_CREDIT_ANALYST_EK can not create order split
        if (
            this.currentRole === "ROLE_BANKER_EK" ||
            this.currentRole === "ROLE_CREDIT_ANALYST_EK"
        ) {
            return;
        }

        if (this.simulationForm && this.simulationForm.invalid) {
            Object.keys(this.simulationForm.controls).forEach((controlName) =>
                this.simulationForm.controls[controlName].markAsTouched()
            );

            const message = `vous devez remplir tous les champs obligatoires !`;
            this.layoutUtilsService.showActionNotification(
                message,
                MessageType.Update,
                5000,
                true,
                true
            );

            return;
        }

        if (
            this.orderCreationEntity.configurationsDetails &&
            this.orderCreationEntity.configurationsDetails.length > 0
        ) {
            if (this.cartMessage.isLimited === true) {
                this.EkSnackBar.openSnackBar(
                    this.cartMessage.messages,
                    "ok",
                    "error-snackbar"
                );
            } else {
/*                if (this.document.baseURI.includes('https://cpa-bank.admin.ekiclik.dz') && (this.currentRole === 'ROLE_POS_EK' || this.currentRole === 'ROLE_COMMERCIAL_POS_EK')) {
                    // if production env, check phone number before order creation
                    this.checkPhoneNumber(this.simulationForm.controls.phoneNumber.value);
                } else {
                    this.createNewOrderSplit();
                }*/
                this.createNewOrderSplit();
            }
        } else {
            const message = `Veuillez ajouter des produits !`;
            this.layoutUtilsService.showActionNotification(
                message,
                MessageType.Delete,
                5000,
                true,
                true
            );
        }
    }

    checkPhoneNumber(phone: string) {

        if (phone) {

            const dialogRef = this.dialog.open(PhoneNumberValidationPopupComponent,
                {
                    data: {
                        phone: phone
                    },
                    width: '30rem'
                });

            dialogRef.afterClosed().subscribe((code: string) => {
                if (code) {
                    this.createNewOrderSplit();
                }
            });
        } else {
            return;
        }

    }

    createNewOrderSplit() {
        this.loadingSubject$.next(true);

        this.store.dispatch(
            OrderSplitCreated({OrderSplit: this.orderCreationEntity})
        );

        //order creation failed
        this._actions$
            .pipe(ofType(OrderSplitCreationFailed))
            .subscribe((data: any) => {
                if (data && data.error.status === 400) {
                    const message = "La quantité n'est pas disponible !";
                    this.layoutUtilsService.showActionNotification(
                        message,
                        MessageType.Update,
                        5000,
                        true,
                        true
                    );
                } else {
                    if (data.error.status === 500) {
                        const message = data.error.error.message;
                        this.layoutUtilsService.showActionNotification(
                            message,
                            MessageType.Update,
                            5000,
                            true,
                            true
                        );
                    } else {
                        const message = `il y a une erreur dans la création du dossier !`;
                        this.layoutUtilsService.showActionNotification(
                            message,
                            MessageType.Update,
                            5000,
                            true,
                            true
                        );
                    }
                }

                //stop spinner
                this.loadingSubject$.next(false);
            });

        //order updated successfully

        this._actions$
            .pipe(ofType(OrderSplitCreatedSuccessfully))
            .subscribe((data: any) => {
                this.store
                    .pipe(select(selectLastCreatedOrderSplitId))
                    .subscribe((newId) => {
                        if (!newId) {
                            return;
                        }

                        this.updateClient();

                        const message = `New order successfully has been added.`;
                        this.layoutUtilsService.showActionNotification(
                            message,
                            MessageType.Create,
                            5000,
                            true,
                            true
                        );

                        this.orderSplitService
                            .getOrderSplitById(newId)
                            .subscribe((order) => {
                                this.orderSplitService.selectedOrderSubject.next(order);
                                this.loadingSubject$.next(false);
                                this.setSocialInfo(
                                    order.folderId,
                                    "OTHER",
                                    this.simulationForm.controls.profSit.value,
                                    this.simulationForm.controls.persoSit.value
                                );
                                this.router.navigateByUrl(
                                    "/ek-ecommerce/folder/edit/" +
                                    newId +
                                    "/" +
                                    order.folderId +
                                    "?step=2"
                                );
                            });
                    });
            });
    }

    setCartCapacity(event) {
        this.cartCapacity = event;
        localStorage.setItem("cartCapacity", JSON.stringify(this.cartCapacity));
    }

    updateClient() {
        this.client.firstname = this.simulationForm.controls.firstName.value;
        this.client.lastname = this.simulationForm.controls.lastName.value;

        const updatedCustomer: Update<ClientModel> = {
            id: this.client.id,
            changes: this.client,
        };

        this.store.dispatch(
            ClientActions.CustomerUpdated({
                customer: this.client,
                partialCustomer: updatedCustomer,
            })
        );

        this._myData$.next(this.client);
    }

    updateOrderSplitAdmin() {
        if (
            (this.currentRole === "ROLE_POS_EK" &&
                this.folder.folderState !== "EK_FOLDER_CREATED" &&
                this.folder.folderState !== "EK_FOLDER_RESERVE") ||
            (this.currentRole === "ROLE_CREDIT_ANALYST_EK" &&
                this.folder.folderState !== "EK_BANK_RESERVE" &&
                this.folder.folderState !== "EK_FOLDER_IN_PROGRESS")
        ) {
            this.step = 2;
            this.router.navigate([], {queryParams: {step: 2}});
            return;
        } else {
            //if any field has been modified
            if (
                this.simulationForm &&
                this.simulationForm.touched &&
                this.simulationForm.dirty
            ) {
                //remove space of decimal pipe to avoid form validation error
                let salaryValue = this.simulationForm
                    .get("salary")
                    .value.toString()
                    .replace(" ", "");
                this.simulationForm.get("salary").setValue(salaryValue);

                if (this.simulationForm.valid) {
                    if (this.cartMessage.isLimited === true) {
                        this.EkSnackBar.openSnackBar(
                            this.cartMessage.messages,
                            "ok",
                            "error-snackbar"
                        );
                    } else {
                        this.loadingSubject$.next(true);

                        let OrderSplit = this.prepareOrderSplit();

                        const updatedOrderSplit: Update<OrderSplitUpdateAdmin> = {
                            id: OrderSplit.id,
                            changes: OrderSplit,
                        };

                        this.store.dispatch(
                            OrderSplitUpdated({
                                idClient: this.orderSplit.clientId,
                                idOrderSplit: this.idOrder,
                                OrderSplit: OrderSplit,
                                partialOrderSplit: updatedOrderSplit,
                            })
                        );

                        //order updated failed
                        this._actions$
                            .pipe(ofType(OrderSplitUpdateFailed))
                            .subscribe((data: any) => {
                                if (data && data.error.status === 403) {
                                    const message = `you can not update FOLDER , Acces Denied!`;
                                    this.layoutUtilsService.showActionNotification(
                                        message,
                                        MessageType.Update,
                                        5000,
                                        true,
                                        true
                                    );
                                } else {
                                    const message = `there is an error in updating folder!`;
                                    this.layoutUtilsService.showActionNotification(
                                        message,
                                        MessageType.Update,
                                        5000,
                                        true,
                                        true
                                    );
                                }

                                //stop spinner
                                this.loadingSubject$.next(false);
                            });

                        //order updated successfully
                        this._actions$
                            .pipe(ofType(OrderSplitUpdatedSuccessfully))
                            .subscribe((data: any) => {
                                //update social info of client after update of order split
                                this.setSocialInfo(
                                    this.idFolder,
                                    "OTHER",
                                    this.simulationForm.controls.profSit.value,
                                    this.simulationForm.controls.persoSit.value
                                );

                                //update client first and last name
                                this.updateClient();

                                const message = `order Split successfully has been updated.`;
                                this.layoutUtilsService.showActionNotification(
                                    message,
                                    MessageType.Update,
                                    5000,
                                    true,
                                    true
                                );
                                this.loadingSubject$.next(false);
                                this.step = 2;
                                this.router.navigate([], {queryParams: {step: 2}});
                                this.refresh();
                            });
                    }
                } else {
                    Object.keys(this.simulationForm.controls).forEach((controlName) =>
                        this.simulationForm.controls[controlName].markAsTouched()
                    );

                    const message = `there is error in some fields!`;
                    this.layoutUtilsService.showActionNotification(
                        message,
                        MessageType.Update,
                        5000,
                        true,
                        true
                    );
                }
            } else {
                this.step = 2;
                this.router.navigate([], {queryParams: {step: 2}});
            }
        }
    }

    navigateToListing() {
        this.router.navigate(["ek-ecommerce/folders"]);
    }

    setSocialInfo(
        id,
        contractType,
        professionalClientCategory,
        socialClientCategory
    ) {
        this.clientOcrInformationService
            .changeSocialProfessionalInformation(
                id,
                contractType,
                professionalClientCategory,
                socialClientCategory
            )
            .subscribe();
    }

    prepareOrderSplit(): any {
        //convert date of birth to dd-MM-yyyy format
        const tempDate = this.datePipe.transform(
            this.orderCreationEntity.dateOfBirthSimulation,
            "dd-MM-yyyy"
        );

        let orderSplitUpdateAdmin = {
            dateOfBirthSimulation: tempDate,
            id: this.orderSplit.id,
            numberOfMonths: this.orderCreationEntity.numberOfMonths,
            phone: this.orderCreationEntity.phone,
            pointOfSaleId: this.selectedRelay
                ? this.selectedRelay.id
                : this.orderSplit.pointOfSaleTo.id,
            professionalClientCategory:
            this.orderCreationEntity.professionalClientCategory,
            salarySimulation: this.orderCreationEntity.salarySimulation,
            socialClientCategory: this.orderCreationEntity.socialClientCategory,
            email: this.orderCreationEntity.email,
        };

        return orderSplitUpdateAdmin;
    }

    refresh() {
        this.loadingSubject$.next(false);

        this.orderSplitService.getById(this.orderSplit.id).subscribe((res) => {
            res.orderItems = res.orderItems.sort(
                (a, b) => a.orderProductId - b.orderProductId
            );
            this.orderSplit = res;
            this.orderSplitService.selectedOrderSubject.next(res);
        });

        this.clientsService.getFolderClientById(this.orderSplit.clientId).subscribe((res) => {
            this._myData$.next(res);
        });
    }

    setClientInfo(event) {
        //update Client info
        this.ocrInfosForm = event;
    }

    areAllChecked(object: any): boolean {
        if (object) {
            return Object.values(object).every((item) => item === true);
        } else {
            return false;
        }
    }

    openErrorPopup() {
        let dialogRef = this.dialog.open(FilesSecondErrorPopupComponent, {
            height: "569px",
            width: "428px",
        });
    }

    openMessagesPopup() {
        const routeInfo = {
            orderId: this.idOrder,
            folderId: this.idFolder,
        };
        this.dialog.open(DocumentsMessagesPopupComponent, {
            height: "fit-content",
            width: "700px",
            maxHeight: "550px",
            panelClass: "ocr-messages-popup",
            data: routeInfo,
        });
    }

    downloadRecapInformation() {
        this.clientOcrInformationService.downloadRecapInformationsText(
            this.idFolder
        );
    }

    updateClientInformationStep3() {
        if (this.ocrInfosForm && this.ocrInfosForm.touched) {
            //update Client
            this.client.socialClientCategory =
                this.ocrInfosForm.controls.socialClientCategory.value;
            this.client.professionalClientCategory =
                this.ocrInfosForm.controls.professionalClientCategory.value;
            this.clientsService.update(this.client).subscribe();

            //update SocialInfo
            this.setSocialInfo(
                this.idFolder,
                this.ocrInfosForm.controls.contractType.value,
                this.ocrInfosForm.controls.professionalClientCategory.value,
                this.ocrInfosForm.controls.socialClientCategory.value
            );

            //update Client Info
            const clientInfoUpdated: ocrInfo = {
                id: this.ocrInfos.id,
                folderId: this.idFolder,
                createdAt: this.ocrInfos.createdAt,
                updatedAt: this.ocrInfos.updatedAt,
                personnelSituation: {
                    personnelSituationAddress: this.ocrInfosForm.controls.address.value,
                    personnelSituationAddressFr: this.ocrInfosForm.controls.personnelSituationAddressFr?.value,
                    personnelSituationCommune: this.ocrInfosForm.controls.commune.value,
                    dependentChild: this.ocrInfosForm.controls.dependentChild.value
                        ? this.ocrInfosForm.controls.dependentChild.value
                        : "",
                    dependentOtherPeople:
                    this.ocrInfosForm.controls.dependentOtherPeople.value,
                    educationalLevel: this.ocrInfosForm.controls.educationalLevel.value,
                    familyState: this.ocrInfosForm.controls.socialClientCategory.value,
                    habitation: this.ocrInfosForm.controls.habitation.value,
                    zipCode: this.ocrInfosForm.controls.zipCode.value,
                },
                personnelInformation: {
                    actOfBrithNumber: this.ocrInfosForm.controls.actOfBrithNumber.value,
                    birthDay: this.datePipe.transform(
                        this.ocrInfosForm.controls.birthDay.value,
                        "dd-MM-yyyy"
                    ),
                    cardType: this.ocrInfosForm.controls.cardType.value,
                    commune: this.ocrInfosForm.controls.commune.value,
                    communeArabic: this.ocrInfosForm.controls.communeArabic.value,
                    birthWilaya: this.ocrInfosForm.controls.birthWilaya.value,
                    deliveryDaira: this.ocrInfosForm.controls.deliveryDaira.value,
                    deliveryDate: this.datePipe.transform(this.ocrInfosForm.controls.deliveryDate.value, 'dd-MM-yyyy'),
                    firstName: this.ocrInfosForm.controls.firstName.value,
                    fistNameEnArab: this.ocrInfosForm.controls.fistNameEnArab.value,
                    idCardNumber: this.ocrInfosForm.controls.idCardNumber.value,
                    lastName: this.ocrInfosForm.controls.lastName.value,
                    lastNameEnArab: this.ocrInfosForm.controls.lastNameEnArab.value,
                    nationalIdNumber: this.ocrInfosForm.controls.nationalIdNumber.value,
                    placeOfBrith: this.ocrInfosForm.controls.placeOfBrith.value,
                    socialSecurityNumber:
                    this.ocrInfosForm.controls.socialSecurityNumber.value,
                    validationDate: this.datePipe.transform(this.ocrInfosForm.controls.validationDate.value, 'dd-MM-yyyy'),
                    socialClientCategory:
                    this.ocrInfosForm.controls.socialClientCategory.value,
                    gender: this.ocrInfosForm.controls.gender.value
                        ? this.ocrInfosForm.controls.gender.value
                        : "None",
                },
                professionalSituation: {
                    personnelSituationAddress: this.ocrInfosForm.controls.address.value,
                    activityDomain: this.ocrInfosForm.controls.activityDomains.value,
                    professionalClientCategory:
                    this.ocrInfosForm.controls.professionalClientCategory.value,
                    contractType: this.ocrInfosForm.controls.contractType.value,
                    employer: this.ocrInfosForm.controls.employer.value,
                    employerAddress: this.ocrInfosForm.controls.employerAddress.value,
                    employerWilaya: this.ocrInfosForm.controls.employerWilaya.value,
                    profession: this.ocrInfosForm.controls.profession.value,
                    professionalPhone: this.ocrInfosForm.controls.professionalPhone.value,
                    recruitmentDate: this.datePipe.transform(this.ocrInfosForm.controls.recruitmentDate.value, 'dd-MM-yyyy'),
                    netSalaryRENA:
                        this.ocrInfosForm.controls.professionalClientCategory.value ===
                        "RETRIED"
                            ? this.ocrInfosForm.controls.montant_net.value?.replace(" ", "")
                            : "",
                    salary:
                        this.ocrInfosForm.controls.professionalClientCategory.value !==
                        "RETRIED"
                            ? this.ocrInfosForm.controls.montant_net.value?.replace(" ", "")
                            : "",
                    monthlySalaryretrait:
                        this.ocrInfosForm.controls.monthlySalary.value?.replace(" ", ""),
                    retraitDate:
                        this.ocrInfosForm.controls.professionalClientCategory.value !==
                        "MILITARY_RETIRED"
                            ? this.datePipe.transform(this.ocrInfosForm.controls.dateRetraite.value, 'dd-MM-yyyy')
                            : "",
                    date_retraite: "",
                    date_recrutement: "",
                    radiationRecruitmentDate:
                        this.ocrInfosForm.controls.professionalClientCategory.value ===
                        "MILITARY_RETIRED"
                            ? this.datePipe.transform(this.ocrInfosForm.controls.dateRetraite.value, 'dd-MM-yyyy')
                            : "",
                    date_radiation:
                        this.ocrInfosForm.controls.professionalClientCategory.value ===
                        "MILITARY_RETIRED"
                            ? this.datePipe.transform(this.ocrInfosForm.controls.dateRetraite.value, 'dd-MM-yyyy')
                            : "",
                },
                financialSituation: {
                    accountKey: this.ocrInfosForm.controls.accountKey.value,
                    accountNumber: this.ocrInfosForm.controls.accountNumber.value,
                    accountNumberCCP: this.ocrInfosForm.controls.accountNumberCCP.value,
                    accountType: this.ocrInfosForm.controls.accountType.value,
                    isClient: this.ocrInfosForm.controls.isClient.value,
                    seniority: this.ocrInfosForm.controls.isClient.value ? this.ocrInfosForm.controls.seniority.value : 0,
                    mortgageAmount: this.ocrInfosForm.controls.mortgageAmount.value ? this.ocrInfosForm.controls.mortgageAmount.value.replace(' ', '') : '',
                    mortgageEndDate: this.datePipe.transform(this.ocrInfosForm.controls.mortgageEndDate.value, 'dd-MM-yyyy'),
                    // mortgageEndDate: this.ocrInfosForm.controls.mortgageEndDate.value,
                    mortgageLenderBank: this.ocrInfosForm.controls.mortgageLenderBank.value ? this.translateLenderBank(this.ocrInfosForm.controls.mortgageLenderBank.value) : 'NONE',
                    carLoanAmount: this.ocrInfosForm.controls.carLoanAmount.value ? this.ocrInfosForm.controls.carLoanAmount.value.replace(' ', '') : '',
                    carCreditEndDate: this.datePipe.transform(this.ocrInfosForm.controls.carCreditEndDate.value, 'dd-MM-yyyy'),
                    // carCreditEndDate: this.ocrInfosForm.controls.carCreditEndDate.value,
                    carLoanLenderBank: this.ocrInfosForm.controls.carLoanLenderBank.value ? this.translateLenderBank(this.ocrInfosForm.controls.carLoanLenderBank.value) : 'NONE',
                    otherCreditAmount: this.ocrInfosForm.controls.otherCreditAmount.value ? this.ocrInfosForm.controls.otherCreditAmount.value.replace(' ', '') : '',
                    // typeOfCreditOther: this.ocrInfosForm.controls.typeOfCreditOther.value,
                    otherCreditEndDate: this.ocrInfosForm.controls.otherCreditEndDate.value ? this.datePipe.transform(this.ocrInfosForm.controls.otherCreditEndDate.value, 'dd-MM-yyyy') : '',
                    // otherCreditEndDate: this.ocrInfosForm.controls.otherCreditEndDate.value,
                    bankLenderCreditOther: this.ocrInfosForm.controls.bankLenderCreditOther.value ? this.translateLenderBank(this.ocrInfosForm.controls.bankLenderCreditOther.value) : 'NONE',
                    // otherIncome:this.ocrInfosForm.controls.otherIncome.value,
                },
                informationAddress: {
                    postNameAscii: "",
                    postAddressAscii: "",
                    communeNameAscii: "",
                    dairaNameAscii: "",
                    wilayaNameAscii: this.ocrInfosForm.controls.wilaya.value,
                },
            };

            this.clientOcrInformationService
                .updateClientInfos(clientInfoUpdated)
                .subscribe({
                    next: (res) => {
                        //update ocr information on step 3
                        this.ocrInfos = res;
                        this.documentService.ocrSubject.next(res);

                        const message = `client information successfully has been updated.`;
                        this.layoutUtilsService.showActionNotification(
                            message,
                            MessageType.Update,
                            5000,
                            true,
                            true
                        );
                        if (this.updateToTwo) {
                            this.updateToTwo = false;
                            this.step = 2;
                            this.router.navigate([], {queryParams: {step: this.step}});
                        } else {
                            this.step = 4;
                            this.router.navigate([], {queryParams: {step: this.step}});
                        }
                    },
                    error: (error) => {
                        const message = `error in update client informations ! try again`;
                        this.layoutUtilsService.showActionNotification(
                            message,
                            MessageType.Update,
                            5000,
                            true,
                            true
                        );
                    },
                });
        } else {
            if (this.updateToTwo) {
                this.updateToTwo = false;
                this.step = 2;
                this.router.navigate([], {queryParams: {step: this.step}});
            } else {
                this.step = 4;
                this.router.navigate([], {queryParams: {step: this.step}});
            }
        }
    }

    commentFolder() {
        this.router.navigateByUrl(
            "ek-ecommerce/folder/edit/" +
            this.idOrder +
            "/" +
            this.idFolder +
            "/comments"
        );
    }

    translateLenderBank(name: string) {
        if (name === '') return 'NONE';
        return BANK_LENDER.find(option => option.name === name).value;
    }

    sentEmailToClient() {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.height = "400px";
        dialogConfig.width = "400px";

        const dialogRef = this.dialog.open(EkClientEmailComponent, dialogConfig);
        dialogRef.afterClosed().subscribe((data) => {
            if (data.description && this.client.id && data.subject) {
                this.customersService
                    .sendMail(this.client.id, data.description, data.file, data.subject)
                    .subscribe((res) => {
                        if (res) {
                            this.layoutUtilsService.showActionNotification(
                                "Email envoyé avec succès!",
                                MessageType.Update,
                                10000,
                                true,
                                true
                            );
                        } else {
                            this.layoutUtilsService.showActionNotification(
                                "Erreur dans l'envoie de l'email",
                                MessageType.Update,
                                10000,
                                true,
                                true
                            );
                        }
                    });
            }
        });
    }

    setAllValuesToTrue() {
        this.oneByOneValidations = {
            ficheFamilliale: true,
            extraitDeNaissance: true,
            residence: true,
            chifa: true,
            FichePaie1: true,
            FichePaie2: true,
            FichePaie3: true,
            cheque: true,
            cni: true,
            cni_verso: true,
            ccp: true,
            attestationDeTravail: true,
            retraite: true,
            faces: true,
            rena: true,
            radiation: true,
            releveDeCompte: true,
            incomeMilitary: true,
        };
    }

    displayFiles() {
        // this.loadingFilesSubject.next(true);
        const dialogRef = this.dialog.open(DownloadFileDialoguComponent, {
                width: '51rem',
                height: '45rem',
                data: {
                    idFolder: this.idFolder,
                    requestNumber: this.folder.requestNumber,
                    client: this.client.firstname + " " + this.client.lastname,
                }
            }
        );
    }

    canManageComments(folder: FolderModel) {
        return !(this.currentRole === 'ROLE_BANKER_EK' && !BANKER_STATES.includes(folder.folderState));

    }

    getCreditDeets() {
        this.orderSplitService.getCredit(this.idOrder).subscribe((cr) => {
            this.orderSplitService.creditSubject.next(cr)
        });
    }

    ngOnDestroy(): void {
        this.Subs.forEach((sub) => {
            sub.unsubscribe();
        });
        this.setAllValuesToTrue();
        this.folderVerificationService.documents$.next(null);
        this.folderVerificationService.ocrMessages$.next(null);
        this.foldersService.folderStateSubj$.next(null as any);
        this.orderSplitService.selectedOrderSubject.next(null as any);
        this._myData$.next(null as any);
        this.foldersService.folderSbj$.next(null as any);
    }
}

